import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const MembershipCancellationForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_yDC2QfunKvL0RE0cTCKuyc3H', null, captchaRef)}
      id="membership-cancellation-form"
    >
      <h3>Member's First Name *</h3>
      <input
        className="form-control"
        id="firstName"
        name="firstName"

        type="text"
        required
      />
      <h3>Member's Last Name *</h3>
      <input
        className="form-control"
        id="lastName"
        name="lastName"

        type="text"
        required
      />
      <h3>Parent/Guardian Name</h3>
      <input
        className="form-control"
        id="parentGuardianName"
        name="parentGuardianName"

        type="text"
        required
      />
      <h3>Account Email *</h3>
      <input
        className="form-control"
        id="email"
        name="email"

        type="email"
        required
      />
      <h3>Phone Number</h3>
      <input
        className="form-control"
        id="phoneNumber"
        name="phoneNumber"

        type="tel"
      />
      <h3>Did you or a child utilize Boreal Mountain? *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="answer1"
            checked="checked"
            required
            value="yes"
            type="radio"
            name="answer"
          />
          <label htmlFor="answer1">Yes</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="answer2"
            required
            value="no"
            type="radio"
            name="answer"
          />
          <label htmlFor="answer2">No</label>
        </li>
      </ul>
      <h3>Reason for Cancellation *</h3>
      <ul className="l-group">
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation1"
            checked="checked"
            required
            value="Lack of Use"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation1">Lack of Use</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation2"
            checked="checked"
            required
            value="End of Season"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation2">End of Season</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation2"
            checked="checked"
            required
            value="Relocation/Moving"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation3">Relocation/Moving</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation2"
            checked="checked"
            required
            value="Financial Reasons"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation4">Financial Reasons</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation2"
            checked="checked"
            required
            value="Completed Commitment"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation5">Completed Commitment</label>
        </li>
        <li className="l-item">
          <input
            className="radio"
            id="reasonForCancellation2"
            checked="checked"
            required
            value="Other"
            type="radio"
            name="reasonForCancellation"
          />
          <label htmlFor="reasonForCancellation6">Other</label>
        </li>
      </ul>
      <h3>
        Did you or a child utilize our Classes & Clinics? *
        <p />
        <ul className="l-group">
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-1"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-1">Adult Night</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-2"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-2">BMX Clinic</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-3"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-3">Freestyle Trampoline Class</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-4"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-4">Gymnastics Class</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-5"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-5">Ladies Skate Night</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-6"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-6">Mini Shred</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-7"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-7">Ninja Class</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-8"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-8">Parkour/Free Running Class</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-9"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-9">ParkBoark/ParkSki</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-10"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-10">Scooter Session</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-11"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-11">Skate Session</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-12"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-12">Stryder Saturday</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-13"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-13">Tumble Tykes</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="utilization-14"
              name="utilization[]"
              type="checkbox"
              required
            />
            <label htmlFor="utilization-14">None</label>
          </li>
        </ul>
      </h3>
      <ReCAPTCHA
        ref={captchaRef}
        classNameName="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

MembershipCancellationForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

MembershipCancellationForm.defaultProps = {
  buttonText: null,
};
