import {
  componentThemesDefault,
  woodwardTheme,
} from './shared-theme-data';

export const wwDefaultTheme = {
  ...woodwardTheme,
  ...componentThemesDefault,
};

export const wwAltTheme = {
  ...wwDefaultTheme,
};
