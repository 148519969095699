import PropTypes from 'prop-types';
import React, {
  useContext, useRef, useEffect, useState,
} from 'react';

import { AppContext } from '@powdr/context';
import { doubleDigitizer, formatDate } from '@powdr/utils';

import {
  ButtonWrapper,
  AlertIcon,
  AlertMainMeesage,
  AlertMessageWrapper,
  AlertOverlay,
  AlertSubMessage,
  Clock, ComponentWrapper, LogoWrapper, NextButton, PauseButton,
  PreviousButton,
  SlideTitle, StyledTvMain, TvNav, TvNavBar,
  TvNavItem,
  TvNavItemIndex,
  TvNavItemInner,
} from './styles';

export const TvMain = ({
  className,
  title,
  lastUpdatedTime,
  navPages,
  activeItemData,
  children,
  alertData,
  pauseState,
  setPauseState,
  nextItem,
  previousItem,
}) => {
  const { svgs } = useContext(AppContext);
  const [time, setTime] = useState();
  const clockIntervalRef = useRef();

  useEffect(() => {
    clockIntervalRef.current = setInterval(() => {
      const date = new Date();
      const dateInfo = formatDate(date);

      setTime(`${dateInfo.time.hours}:${dateInfo.time.minutes} ${dateInfo.time.ampm.toUpperCase()}`);
    }, 1000);
  }, []);

  return ((
    <StyledTvMain className={className}>
      <ButtonWrapper>
        <PauseButton onClick={() => setPauseState(!pauseState)}>Pause Rotation</PauseButton>
        <PreviousButton onClick={() => previousItem()}>Previous Item</PreviousButton>
        <NextButton onClick={() => nextItem()}>Next Item</NextButton>
      </ButtonWrapper>
      <SlideTitle>
        <h1>{title}</h1>
        {(lastUpdatedTime) && <h2>{`Last Updated at ${lastUpdatedTime}`}</h2>}
      </SlideTitle>
      <LogoWrapper>
        <svgs.SiteLogoTv />
      </LogoWrapper>
      <ComponentWrapper>
        {(!alertData?.enabled)
          ? children
          : (
            <AlertOverlay>
              <AlertIcon
                name={alertData?.iconName}
                width={300}
                height={300}
                $color={alertData?.iconColor}
              />
              <AlertMessageWrapper>
                <AlertMainMeesage>{alertData?.message}</AlertMainMeesage>
                <AlertSubMessage>{alertData?.subMessage}</AlertSubMessage>
              </AlertMessageWrapper>
            </AlertOverlay>
          )}
      </ComponentWrapper>
      {(!alertData?.enabled) && (
        <TvNavBar>
          <TvNav>
            {navPages?.map((i, idx) => (
              <TvNavItem key={i.link} $isActive={activeItemData.link.includes(i.link)}>
                <TvNavItemInner $isActive={activeItemData.link.includes(i.link)}>
                  <TvNavItemIndex>{doubleDigitizer(idx + 1)}</TvNavItemIndex>
                  {` / ${i.name}`}
                </TvNavItemInner>
              </TvNavItem>
            ))}
          </TvNav>
          <Clock>{time}</Clock>
        </TvNavBar>
      )}
    </StyledTvMain>
  ));
};

TvMain.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  navPages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeItemData: PropTypes.shape({
    link: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
  lastUpdatedTime: PropTypes.string,
  alertData: PropTypes.shape({
    enabled: PropTypes.bool,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
  }).isRequired,
  pauseState: PropTypes.bool.isRequired,
  setPauseState: PropTypes.func.isRequired,
  nextItem: PropTypes.func.isRequired,
  previousItem: PropTypes.func.isRequired,
};

TvMain.defaultProps = {
  className: '',
  lastUpdatedTime: null,
};
