import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const PeakPerformerNominationForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form
      onSubmit={(e) => processForm(e, 'buk_Ci0rYWCNhU31mvIUSFVfYVmr', null, captchaRef)}
      id="peak-performer-nomination"
    >
      <h2>Nominator - Who Are You?</h2>
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="nominatorFirstName"
        name="nominatorFirstName"

        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="nominatorLastName"
        name="nominatorLastName"

        type="text"
        required
      />
      <h3>
        Where Do You Work: *
        <p />
        <ul className="l-group">
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-boreal-mountain-california-1"
              name="nominatorWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-boreal-mountain-california-1">
              Boreal Mountain California
            </label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-woodward-tahoe-1"
              name="nominatorWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-woodward-tahoe-1">Woodward Tahoe</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-soda-springs-mountain-resort-1"
              name="nominatorWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-soda-springs-mountain-resort-1">
              Soda Springs Mountain Resort
            </label>
          </li>
        </ul>
      </h3>
      <h3>Department *</h3>
      <input
        className="form-control"
        id="nominator-department"
        name="nominatorDepartment"

        type="text"
        required
      />
      <hr />
      <h2>Nominee - Who Are You Nominating For The Peak Performer Award?</h2>
      <h3>First Name *</h3>
      <input
        className="form-control"
        id="nomineeFirstName"
        name="nomineeFirstName"

        type="text"
        required
      />
      <h3>Last Name *</h3>
      <input
        className="form-control"
        id="nomineeLastName"
        name="nomineeLastName"

        type="text"
        required
      />
      <h3>
        Where Do They Work: *
        <p />
        <ul className="l-group">
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-boreal-mountain-california-2"
              name="nomineeWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-boreal-mountain-california-2">
              Boreal Mountain California
            </label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-woodward-tahoe-2"
              name="nomineeWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-woodward-tahoe-2">Woodward Tahoe</label>
          </li>
          <li className="l-item">
            <input
              className="checkbox"
              id="nominator-soda-springs-mountain-resort-2"
              name="nomineeWork[]"
              type="checkbox"
            />
            <label htmlFor="nominator-soda-springs-mountain-resort-2">
              Soda Springs Mountain Resort
            </label>
          </li>
        </ul>
      </h3>
      <h3>Department *</h3>
      <input
        className="form-control"
        id="nominee-department"
        name="nominee-department"

        type="text"
        required
      />
      <h2>PEAK PERFORMER STORY - WHY YOU’RE NOMINATING THIS PEER?</h2>
      <h3>Describe *</h3>
      <textarea
        id="describe"
        rows="9"
        cols="33"
        name="describe"
        required
      />
      <ReCAPTCHA
        ref={captchaRef}
        classNameName="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />
      <button type="submit" value="Submit" disabled={!isUserVerified}>
        {buttonText || ButtonText.SUBMIT}
      </button>
    </form>
  );
};

PeakPerformerNominationForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

PeakPerformerNominationForm.defaultProps = {
  buttonText: null,
};
