import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';

import { componentThemesDefault } from './shared-theme-data';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#666666',
    [Prop.LINK_TXT]: '#64CAC7',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#FFFFFF',
    [Prop.BORDER]: '#000000',
    [Prop.ELEMENT]: '#e0e0e0',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.ICON]: '#64CAC7',
    [Prop.ICON_HOVER]: '#000000',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#64CAC7',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#3B3937',
    [Prop.LINK_TXT]: '#ffffff',
    [Prop.LINK_TXT_HOVER]: '#000000',
    [Prop.BTN_BG]: '#FFD200',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.BORDER]: '#000000',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#FFD200',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#ffffff',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#000000',
    [Prop.HEADER]: '#64CAC7',
    [Prop.CONTENT]: '#ffffff',
    [Prop.LINK_TXT]: '#64CAC7',
    [Prop.LINK_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG]: '#ffd200',
    [Prop.BTN_TXT]: '#3b3937',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#4d4d4d',
    [Prop.BTN_TXT_INACTIVE]: '#ffd200',
    [Prop.BORDER]: '#64CAC7',
    [Prop.ELEMENT]: '#4d4d4d',
    [Prop.ELEMENT_TXT]: '#e0e0e0',
    [Prop.ICON]: '#ffffff',
    [Prop.ICON_HOVER]: '#64CAC7',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#ffd200',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#000000',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#666666',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#FFFFFF',
    [Prop.BTN_BG_HOVER]: '#000000',
    [Prop.BTN_TXT_HOVER]: '#FFD200',
    [Prop.BTN_BG_ACTIVE]: '#ffffff',
    [Prop.BTN_TXT_ACTIVE]: '#000000',
    [Prop.BTN_BG_INACTIVE]: '#A29B6B',
    [Prop.BTN_TXT_INACTIVE]: '#000000',
    [Prop.BORDER]: '#000000',
    [Prop.ELEMENT]: '#000000',
    [Prop.ELEMENT_TXT]: '#e0e0e0',
    [Prop.ICON]: '#000000',
    [Prop.ICON_HOVER]: '#FFFFFF',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#f8f8f8',
    [Prop.HEADER]: '#000000',
    [Prop.CONTENT]: '#4a4a4a',
    [Prop.LINK_TXT]: '#000000',
    [Prop.LINK_TXT_HOVER]: '#64CAC7',
    [Prop.BTN_BG]: '#000000',
    [Prop.BTN_TXT]: '#ffd200',
    [Prop.BTN_BG_HOVER]: '#ffd200',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#000000',
    [Prop.BTN_TXT_ACTIVE]: '#ffd200',
    [Prop.BTN_BG_INACTIVE]: '#b4b4b4',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#64CAC7',
    [Prop.ELEMENT]: '#b4b4b4',
    [Prop.ELEMENT_TXT]: '#000000',
    [Prop.ICON]: '#64CAC7',
    [Prop.ICON_HOVER]: '#000000',
  },
  ...componentThemesDefault,
};
